html {
    height: 100%;
    margin: 0;
}

body {
    height: 100%;
    margin: 0;
}

.background {
    background-image: url('../public/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
    min-height: 100vh;
}

.title {
    width: 700px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.description {
    font-family: "AbhayaLibreExtraBold";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
    text-align: center;

    color: #FFFDD9;
    width: 60%;
    margin: -10px 20% 0 20%;
}

.generate-button {
    width: 279px;
    height: 57px;

    background: linear-gradient(93.23deg, #FFFFFF 11.88%, #EFE9E9 87.28%);
    border-radius: 16px;
    border-width: 0;

    font-family: "AbhayaLibreExtraBold";
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;

    display: block;
    margin: 40px auto 40px auto;

    cursor: pointer;
}

.movies-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    margin-top: 60px;
}

.movie-details {
    display: inline;
    min-height: 263px;
}

.movie-card {
    width: 422px;
    background: linear-gradient(93.23deg, #FFFFFF 11.88%, #EFE9E9 87.28%);
    border-radius: 16px;
    padding-right: 18px;
    display: flex;
    position: relative;
}

.movie-image {
    width: 140px;
    height: 195px;
    border-radius: 7px;
    margin: 18px 15px 0px 15px;
    display: inline;
}

.movie-rank {
    color: #757575;
    font-family: 'AbhayaLibreExtraBold';
    font-size: 24px;
    margin-top: 22.4px;
}

.movie-title {
    font-family: 'AbhayaLibreExtraBold';
    font-size: 24px;
    margin-top: 5px;
}

.movie-year {
    font-family: 'AbhayaLibreExtraBold';
    font-size: 18px;
    margin-top: 2px;
}

.movie-desc {
    font-family: 'AbhayaLibre';
    font-size: 16px;
    margin-bottom: 50px;
}

.divider {
    background-color: #A8A8A8;
    height: 1px;
    width: 233px;
    margin: 20px 0 16px 0;
}

.eyeball {
    width: 31px;
}

.seen-it-link {
    position: relative;
    display: flex;
    align-items: center;
    bottom: 10px;
    right: 18px;
    position: absolute;
    cursor: pointer;
}

.seen-it {
    display: inline;
    font-family: 'AbhayaLibreExtraBold';
    font-size: 18px;
    margin-left: 9px;
}

@font-face {
    font-family: "AbhayaLibreExtraBold";
    src: local("AbhayaLibreExtraBold"),
    url("./fonts/AbhayaLibre-ExtraBold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "AbhayaLibre";
    src: local("AbhayaLibre"),
    url("./fonts/AbhayaLibre-Regular.ttf") format("truetype");
}